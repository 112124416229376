import { PageProps } from "gatsby";
import React from "react";

import { Search } from "../../components/pages/Search";

import { dnProps } from "../../js/utils";

const SearchPage = (props: PageProps) => (
  <Search {...{ ...dnProps, ...props }} />
);

export default SearchPage;
