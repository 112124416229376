import React from "react";
import { PageComponentProps } from "../../types/types";
import Layout from "../parts/Layout";
import { WebsiteSearchBox } from "../parts/WebsiteSearchBox";

import * as pageStyles from "../global-styles/page.module.css";
import * as utilStyles from "../global-styles/util.module.css";

export const Search = (props: PageComponentProps) => {
  const title = "Search this Website";
  const description = "Search the Clairnote music notation website.";
  return (
    <Layout title={title} description={description} {...props}>
      <article className="page">
        <h1
          className={`title is-3 ${pageStyles.pageTitle} ${utilStyles.textAlignLeft}`}
        >
          {title}
        </h1>
        <p>
          <WebsiteSearchBox
            sites="clairnote.org"
            placeholder=""
            searchButtonText="Search"
          />
        </p>
        <p>Search clairnote.org, including blog posts, using DuckDuckGo.</p>
        <p>The results will appear in a new browser tab on duckduckgo.com.</p>
      </article>
    </Layout>
  );
};
