import React, { useState } from "react";

/**
 * @see https://stackoverflow.com/questions/7259209/simple-site-search-with-duckduckgo
 */
export const WebsiteSearchBox = (props: {
  placeholder: string;
  sites: string;
  searchButtonText: string;
}) => {
  const [input, setInput] = useState("");
  const route = `https://duckduckgo.com/?q=${input}&sites=${props.sites}`;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        window.open(route, "_blank");
      }}
    >
      <div className="field has-addons">
        <div className="control">
          <input
            id="searchBox"
            className="input"
            type="text"
            placeholder={props.placeholder}
            name="q"
            value={input}
            onChange={(event) => setInput(event.target.value)}
          />
        </div>
        <div className="control">
          <button className="button" id="" value="Search">
            {props.searchButtonText}
          </button>
        </div>
      </div>
    </form>
  );
};
